
import {
  // balanceCapacitors,
  capacitors,
  connected,
  totalChargeRight,
  connectors,
  balanceConnected,
} from "@/state/capacitor.state";
import { defineComponent } from "vue";

export default defineComponent({
  setup() {
    function updateCapacity(i: number, value: number) {
      capacitors[i].capacity = value;
    }

    function updateCharge(i: number, value: number) {
      if (capacitors[i] == undefined) return; // REMOVAL PROTECTION
      capacitors[i].charge = value;
    }

    // async function updateActive(i: number, value: boolean) {
    //   capacitors[i].active = value;
    //   balanceCapacitors();
    // }

    async function capCapacity(i: number) {
      if (capacitors[i].charge > capacitors[i].capacity) {
        capacitors[i].charge = capacitors[i].capacity;
      }

      balanceConnected();
    }

    return {
      capacitors,

      updateCapacity,
      updateCharge,
      // updateActive,

      capCapacity,

      connected,

      totalChargeRight,
      connectors,
    };
  },
});
