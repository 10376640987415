<template>
  <h1>Kondenzátory - paralelní zapojení</h1>
  <section>
    <svg viewBox="0 0 400 200" width="400" height="200">
      <g opacity="0.04">
        <path
          d="M215.469 61.1935C215.494 57.1939 214.229 53.2929 211.861 50.0691L198.975 35L198.9 98.4213C198.963 98.9198 198.988 99.4223 198.975 99.9246C198.837 103.332 197.881 103.742 196.107 101.154C196.047 101.013 195.974 100.944 195.974 100.872C195.424 100.049 194.808 99.273 194.131 98.5506C191.324 96.0372 188.314 93.7595 185.133 91.7406C179.889 88.4326 174.916 84.7128 170.262 80.6162C165.546 76.1342 162.008 70.5574 159.964 64.3805L140.334 80.4358C142.045 87.2649 145.603 93.4909 150.62 98.4303C155.198 103 160.554 106.718 166.438 109.407C170.491 111.007 174.162 113.443 177.21 116.557C179.342 119.522 180.914 122.852 181.849 126.383H213.635L201.834 110.697C200.331 108.313 200.95 107.017 203.813 106.948H260L243.906 87.7208H215.469V61.1935Z"
          fill="black"
        />
        <path
          d="M242.95 165.069L226.043 142.63H167.944C166.654 138.537 164.721 134.675 162.216 131.19C159.325 127.697 155.593 124.994 151.371 123.337C145.48 120.845 140.113 117.261 135.554 112.775C130.021 107.455 126.403 100.455 125.262 92.8651L125.319 92.4592L102 111.545C104.666 118.78 108.747 125.41 114.005 131.049C118.37 136.174 123.701 140.389 129.694 143.454C134.246 145.444 138.264 148.481 141.42 152.318C144.493 156.137 146.892 160.452 148.515 165.078H242.95V165.069Z"
          fill="black"
        />
      </g>
      <line x1="139" y1="15" x2="211" y2="15" stroke="black" stroke-width="4" />
      <line x1="53" y1="15" x2="111" y2="15" stroke="black" stroke-width="4" />
      <line x1="4" y1="15" x2="25" y2="15" stroke="black" stroke-width="4" />
      <line x1="239" y1="15" x2="311" y2="15" stroke="black" stroke-width="4" />
      <line x1="339" y1="15" x2="379" y2="15" stroke="black" stroke-width="4" />
      <line x1="4" y1="185" x2="379" y2="185" stroke="black" stroke-width="4" />
      <line x1="177" y1="13" x2="177" y2="97" stroke="black" stroke-width="4" />

      <!-- RESISTOR -->
      <line x1="377" y1="13" x2="377" y2="84" stroke="black" stroke-width="4" />
      <line
        x1="377"
        y1="116"
        x2="377"
        y2="187"
        stroke="black"
        stroke-width="4"
      />
      <rect
        x="369"
        y="82"
        width="16"
        height="36"
        stroke="black"
        stroke-width="4"
        fill="transparent"
      />
      
      <g clip-path="url(#clip0)">
<path d="M397.062 97.364C397.062 98.134 396.642 98.596 395.746 98.596H394.262V96.16H395.746C396.642 96.16 397.062 96.636 397.062 97.364ZM391.868 94.172V104H394.262V100.29H394.836L396.88 104H399.582L397.314 100.108C398.826 99.66 399.498 98.498 399.498 97.266C399.498 95.558 398.294 94.172 395.886 94.172H391.868Z" fill="black"/>
</g>
<defs>
<clipPath id="clip0">
<rect width="400" height="200" fill="white"/>
</clipPath>
</defs>

      <line x1="277" y1="13" x2="277" y2="97" stroke="black" stroke-width="4" />
      <line x1="6" y1="13" x2="6" y2="81" stroke="black" stroke-width="4" />
      <line x1="77" y1="13" x2="77" y2="97" stroke="black" stroke-width="4" />
      <line
        x1="177"
        y1="103"
        x2="177"
        y2="187"
        stroke="black"
        stroke-width="4"
      />
      <line
        x1="277"
        y1="103"
        x2="277"
        y2="187"
        stroke="black"
        stroke-width="4"
      />
      <line x1="6" y1="119" x2="6" y2="187" stroke="black" stroke-width="4" />
      <line
        x1="211"
        y1="15"
        x2="239"
        y2="15"
        :stroke="connectors[2].connected ? 'rgba(0,0,0,1)' : 'rgba(0,0,0,0.08)'"
        stroke-width="4"
      />
      <line
        x1="111"
        y1="15"
        x2="139"
        y2="15"
        :stroke="connectors[1].connected ? 'rgba(0,0,0,1)' : 'rgba(0,0,0,0.08)'"
        stroke-width="4"
      />
      <line
        x1="25"
        y1="15"
        x2="53"
        y2="15"
        :stroke="connectors[0].connected ? 'rgba(0,0,0,1)' : 'rgba(0,0,0,0.08)'"
        stroke-width="4"
      />
      <line
        x1="311"
        y1="15"
        x2="339"
        y2="15"
        :stroke="connectors[3].connected ? 'rgba(0,0,0,1)' : 'rgba(0,0,0,0.08)'"
        stroke-width="4"
      />
      <line x1="77" y1="103" x2="77" y2="187" stroke="black" stroke-width="4" />
      <line x1="161" y1="95" x2="193" y2="95" stroke="black" stroke-width="4" />
      <line x1="261" y1="95" x2="293" y2="95" stroke="black" stroke-width="4" />
      <line x1="61" y1="95" x2="93" y2="95" stroke="black" stroke-width="4" />
      <line
        x1="161"
        y1="105"
        x2="193"
        y2="105"
        stroke="black"
        stroke-width="4"
      />
      <line
        x1="261"
        y1="105"
        x2="293"
        y2="105"
        stroke="black"
        stroke-width="4"
      />
      <line x1="61" y1="105" x2="93" y2="105" stroke="black" stroke-width="4" />
      <path
        d="M1.826 100.038C1.826 102.754 3.604 104.098 5.886 104.098C8.154 104.098 10.058 102.754 10.058 100.038V94.172H7.664V100.052C7.664 101.214 7.062 101.886 5.928 101.886C4.794 101.886 4.22 101.214 4.22 100.052V94.172H1.826V100.038Z"
        fill="black"
      />
      <path
        d="M20.262 76.53V79.036H17.826V81.178H20.262V83.67H22.53V81.178H24.966V79.036H22.53V76.53H20.262Z"
        fill="black"
      />
      <path d="M37.764 34H40.256V23.78H36.378V26.006H37.764V34Z" fill="black" />
      <path
        d="M121.546 33.86H128.574V31.914H124.794C126.418 30.668 128.42 28.876 128.42 26.636C128.42 24.914 127.384 23.556 125.06 23.556C123.198 23.556 121.588 24.62 121.518 27.098H123.828C123.828 26.132 124.248 25.572 124.962 25.572C125.69 25.572 126.026 26.048 126.026 26.79C126.026 28.512 123.716 30.29 121.546 32.082V33.86Z"
        fill="black"
      />
      <path
        d="M85.392 119.08C85.392 121.544 86.918 123.112 88.822 123.112C89.984 123.112 90.81 122.58 91.244 121.894V123H93.638V115.188H91.244V116.294C90.824 115.608 89.998 115.076 88.836 115.076C86.918 115.076 85.392 116.616 85.392 119.08ZM91.244 119.094C91.244 120.34 90.446 121.026 89.536 121.026C88.64 121.026 87.828 120.326 87.828 119.08C87.828 117.834 88.64 117.162 89.536 117.162C90.446 117.162 91.244 117.848 91.244 119.094Z"
        fill="black"
      />
      <path
        d="M190.262 117.294V113.64H187.868V124H190.262V122.908C190.696 123.594 191.522 124.112 192.67 124.112C194.588 124.112 196.114 122.544 196.114 120.08C196.114 117.616 194.602 116.076 192.67 116.076C191.55 116.076 190.696 116.594 190.262 117.294ZM193.678 120.08C193.678 121.326 192.866 122.026 191.956 122.026C191.06 122.026 190.248 121.34 190.248 120.094C190.248 118.848 191.06 118.162 191.956 118.162C192.866 118.162 193.678 118.834 193.678 120.08Z"
        fill="black"
      />
      <path
        d="M285.392 120.094C285.392 122.572 287.016 124.112 289.312 124.112C291.272 124.112 292.658 122.978 293.064 121.172H290.516C290.306 121.746 289.928 122.068 289.27 122.068C288.416 122.068 287.828 121.396 287.828 120.094C287.828 118.792 288.416 118.12 289.27 118.12C289.928 118.12 290.32 118.47 290.516 119.016H293.064C292.658 117.154 291.272 116.076 289.312 116.076C287.016 116.076 285.392 117.63 285.392 120.094Z"
        fill="black"
      />
      <path
        d="M220.714 26.664H223.052C223.094 26.104 223.374 25.544 224.144 25.544C224.788 25.544 225.194 25.922 225.194 26.608C225.194 27.532 224.41 27.728 223.598 27.728H223.15V29.674H223.598C224.844 29.674 225.39 30.038 225.39 30.948C225.39 31.62 224.984 32.096 224.242 32.096C223.36 32.096 222.926 31.564 222.912 30.766H220.588C220.658 33.076 222.198 34.084 224.326 34.084C226.468 34.084 227.7 32.852 227.7 31.13C227.7 29.786 227 29.002 225.978 28.666V28.61C226.678 28.372 227.518 27.658 227.518 26.314C227.518 24.774 226.356 23.542 224.2 23.542C222.114 23.542 220.798 24.648 220.714 26.664Z"
        fill="black"
      />
      <path
        d="M325.39 34H327.784V32.18H328.932V30.108H327.784V23.92H325.11L320.686 30.248V32.18H325.39V34ZM323.206 30.108L325.558 26.552V30.108H323.206Z"
        fill="black"
      />
      <path d="M17.728 119.08H23.888V117.092H17.728V119.08Z" fill="black" />
      <circle
        cx="6"
        cy="80"
        r="2"
        fill="black"
        stroke="black"
        stroke-width="4"
      />
      <circle
        cx="6"
        cy="118"
        r="2"
        fill="black"
        stroke="black"
        stroke-width="4"
      />
      <circle
        cx="77"
        cy="185"
        r="2"
        fill="black"
        stroke="black"
        stroke-width="4"
      />
      <circle
        cx="77"
        cy="15"
        r="2"
        fill="black"
        stroke="black"
        stroke-width="4"
      />
      <circle
        cx="177"
        cy="15"
        r="2"
        fill="black"
        stroke="black"
        stroke-width="4"
      />
      <circle
        cx="277"
        cy="15"
        r="2"
        fill="black"
        stroke="black"
        stroke-width="4"
      />
      <circle
        cx="277"
        cy="185"
        r="2"
        fill="black"
        stroke="black"
        stroke-width="4"
      />
      <circle
        cx="177"
        cy="185"
        r="2"
        fill="black"
        stroke="black"
        stroke-width="4"
      />

      <!-- CUSTOM CAPACITORS -->

      <rect
        v-for="(capacitor, i, k) in capacitors"
        :key="k"
        :width="capacitor.capacity"
        height="102"
        :x="i * 100 + 102"
        y="49"
        style="fill: #faf8f7"
        stroke="black"
        stroke-width="2"
      />

      <rect
        v-for="(capacitor, i, k) in capacitors"
        :key="k"
        :width="capacitor.capacity - 2"
        :height="(capacitor.charge / capacitor.capacity) * 100"
        :x="i * 100 + 103"
        :y="150 - (capacitor.charge / capacitor.capacity) * 100"
        style="fill: #e9671e"
      />
    </svg>

    <div class="controls">
      <div class="connections-controls">
        <template v-for="(connector, i, k) in connectors" :key="k">
          <!-- <label
            >Přepínač <b>{{ i + 1 }}</b></label
          > -->
          <span
            >Přepínač <b>{{ i + 1 }}</b></span
          >
          <label class="switch">
            <input type="checkbox" v-model="connector.connected" />
            <span class="slider"></span>
          </label>
        </template>
      </div>

      <div class="capacitors-control">
        <div v-for="(capacitor, i, k) in capacitors" :key="k">
          <label
            >Kondenzátor <b>{{ (i + 10).toString(36) }}</b> -
            {{ capacitor.capacity }} F
          </label>
          <input
            class="sliderbar"
            type="range"
            min="20"
            max="50"
            v-model.number="capacitor.capacity"
            @input="capCapacity(i)"
          />
          <!-- {{ capacitor.capacity }} -->
        </div>
      </div>
    </div>

    <!-- <button
      @mousedown="connected = false"
      @mouseup="connected = true"
      @touchstart="connected = false"
      @touchend="connected = true"
    >
      Vybít kondenzátory
    </button> -->
  </section>
</template>

<script lang="ts">
import {
  // balanceCapacitors,
  capacitors,
  connected,
  totalChargeRight,
  connectors,
  balanceConnected,
} from "@/state/capacitor.state";
import { defineComponent } from "vue";

export default defineComponent({
  setup() {
    function updateCapacity(i: number, value: number) {
      capacitors[i].capacity = value;
    }

    function updateCharge(i: number, value: number) {
      if (capacitors[i] == undefined) return; // REMOVAL PROTECTION
      capacitors[i].charge = value;
    }

    // async function updateActive(i: number, value: boolean) {
    //   capacitors[i].active = value;
    //   balanceCapacitors();
    // }

    async function capCapacity(i: number) {
      if (capacitors[i].charge > capacitors[i].capacity) {
        capacitors[i].charge = capacitors[i].capacity;
      }

      balanceConnected();
    }

    return {
      capacitors,

      updateCapacity,
      updateCharge,
      // updateActive,

      capCapacity,

      connected,

      totalChargeRight,
      connectors,
    };
  },
});
</script>


<style lang="scss" scoped>
section {
  display: flex;
  flex-flow: column;
  align-items: center;
}
.capacitors-control {
  margin: 24px 0;
  display: flex;
  align-items: flex-start;
  flex-flow: column;

  & > div {
    display: grid;
    grid-auto-flow: row;

    margin: 10px 0;
    align-items: center;
    row-gap: 1px;
  }

  input {
    width: 140px;
  }
}

.connections-controls {
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 14px;
  column-gap: 8px;
}

.controls {
  margin: 24px 0;

  display: flex;
  flex-flow: column;
  align-items: flex-start;
}

svg {
  width: 100%;
  height: auto;

  max-width: 520px;
  shape-rendering: geometricPrecision;

  margin: 14px 0;
}

@media screen and (max-width: 800px) {
  h1 {
    font-size: 1.6rem;
  }
}

/* The slider itself */
.sliderbar {
  -webkit-appearance: none; /* Override default CSS styles */
  appearance: none;
  width: 100%; /* Full-width */
  height: 20px; /* Specified height */
  background: rgb(204, 198, 198); /* Grey background */
  padding: 0 2px;
  outline: none; /* Remove outline */
  border-radius: 99px;
}

/* Mouse-over effects */
.sliderbar:hover {
  opacity: 1; /* Fully shown on mouse-over */
}

/* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */
.sliderbar::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default look */
  appearance: none;
  width: 16px; /* Set a specific slider handle width */
  height: 16px; /* Slider handle height */
  // background: #ef5f14; /* Green background */
  background: white;
  cursor: pointer; /* Cursor on hover */

  border-radius: 99px;
}

.sliderbar::-moz-range-thumb {
  width: 25px; /* Set a specific slider handle width */
  height: 25px; /* Slider handle height */
  // background: #ef5f14; /* Green background */
  background: white;
  cursor: pointer; /* Cursor on hover */

  border-radius: 99px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 44px;
  height: 22px;

  margin-left: 12px;

  border-radius: 99px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgb(204, 198, 198);
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 99px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;

  border-radius: 99px;
}

input:checked + .slider {
  background-color: #75fc45;
}

input:checked + .slider:before {
  transform: translateX(22px);
}
</style>