import { Capacitor } from "@/models/capacitor.model";
import { reactive, ref } from "@vue/reactivity";
import { watch } from "@vue/runtime-core";
import gsap from "gsap";

const BASE_WIDTH = 20;

const capacitors = reactive(
    [
        {
            capacity: BASE_WIDTH,
            charge: 0,
        },
        {
            capacity: BASE_WIDTH,
            charge: 0,
        },
        {
            capacity: BASE_WIDTH,
            charge: 0,
        }
    ] as Capacitor[]);

export const connectors = reactive(
    [
        {
            connected: false
        },
        {
            connected: false
        },
        {
            connected: false
        },
        {
            connected: false
        }
    ]);


export const totalChargeRight = ref(0);

function balanceRange(left: number, right: number) {
    const caps = capacitors.slice(left, right + 1);

    const totalCapacity = caps.reduce((p, c) => p + c.capacity, 0);
    const totalCharge = caps.reduce((p, c) => p + c.charge, 0);

    // const charges = Array(caps.length).fill(0);

    // let tries = 0;
    // let chargeLeft = totalCharge;
    // while (chargeLeft > 0) {
    //     for (let i = 0; i < caps.length; i++) {
    //         if (charges[i] >= caps[i].capacity) {
    //             tries++;
    //             continue;
    //         }

    //         tries = 0;

    //         // charges[i] += 1;
    //         // chargeLeft -= 1;

    //         const chunk = Math.min((caps[i].capacity / BASE_WIDTH), chargeLeft);

    //         charges[i] += chunk;
    //         chargeLeft -= chunk;
    //     }

    //     if (tries > caps.length) break;
    // }

    const bias = totalCharge / totalCapacity;
    const charges = caps.map(c => {
        return c.capacity * bias;
    });


    for (let i = 0; i < caps.length; i++) {
        // CHECK IF BALANCED
        // if (caps[i].charge == charges[i]) continue;

        // V2
        if (Math.abs(caps[i].charge - charges[i]) < 0.1) continue;

        gsap.to(caps[i], 0.35, { charge: charges[i] });
    }
}

export function balanceConnected() {
    // TODO remove
    // if (connectors[3].connected) return;

    let left = -1;
    let right = -1;

    for (let i = 0; i < connectors.length; i++) {
        const connector = connectors[i];

        if (left == -1 && connector.connected) left = i - 1;

        // last
        if (i == connectors.length - 1) right = i;

        if (!connector.connected && left != -1) {
            right = i - 1;
            break;
        }
    }


    console.log(left, right);
    if (left < 0 || right < 0) return;

    balanceRange(left, right);
}

// balance capacitor groups
watch(connectors, () => {
    balanceConnected();
});

// watch(capacitors, () => {
//     balanceConnected();
// });


const paused = ref(false);
const connected = ref(true);

function energetize() {
    requestAnimationFrame(() => {

        if (paused.value) {
            energetize();
            return;
        }


        let end = 0;
        for (let i = 0; i < connectors.length; i++) {
            if (connectors[i].connected) end += 1;
            else break;
        }

        let start = connectors.length - 1;
        for (let i = connectors.length - 1; i > 0; i--) { // > 0 because of 4 connectors
            if (connectors[i].connected) start -= 1;
            else break;
        }

        const discharge = connectors[connectors.length - 1].connected;

        if (discharge) {
            const caps = capacitors.slice(start, capacitors.length);

            totalChargeRight.value = caps.reduce((n, p) => n + p.charge, 0);

            for (const capacitor of caps) {
                const bias = capacitor.capacity / BASE_WIDTH;

                if (capacitor.charge > 0) {
                    capacitor.charge = Math.max(capacitor.charge - (0.2 / caps.length * bias), 0);
                }
            }
        }
        // else {
        const caps = capacitors.slice(0, end);
        for (const capacitor of caps) {
            const bias = capacitor.capacity / BASE_WIDTH;

            if (capacitor.charge < capacitor.capacity) {
                capacitor.charge = Math.min(capacitor.charge + (0.4 / caps.length * bias), capacitor.capacity);
            }
        }
        // }


        energetize();
    });
}

energetize();



export {
    capacitors,
    // balanceCapacitors,

    paused,
    connected
}